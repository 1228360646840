import {getInitialSite, siteId} from "./map/site_management";
import mapboxgl from "mapbox-gl";
import Config from "./config/constants";
import MapConfig from "./config/map_config";
import {createExceptionRow, fitMapToBounds, geofenceAjaxCall, imageDataAjaxCall, tunnelAjaxCall} from "./map/map_ajax";
import {addPolygonsMouseOver} from "./map/map";
import tippy from "tippy.js";
import Swal from 'sweetalert2';
import moment from "moment";

$(document).on('turbolinks:load', () => {
    let personnel_switch = $("#personnel_switch");
    if(personnel_switch.length) {
        listenForMapClick();
        listenForSwitch(personnel_switch);
        listenForExpand();
        listenForEditClick();
        listenForCheckbox();
        const lost_link = $(".user-dash-lost-link");
        const broken_link = $(".user-dash-broken-link");
        listenForUnallocation(lost_link, broken_link);
    }

    if ($('#assign_personnel_map').length) {
        $.when(getInitialSite()).done(() => {
            initiateAssignPersonnelMap();
        })
    }
});
function initiateAssignPersonnelMap() {
    const siteId = document.getElementById("site_id").value;
    const siteCoordinates = document.getElementById("site_coordinates").value;
    const geofenceIds = document.getElementById("geofence_ids").value;
    mapboxgl.accessToken = Config.mapboxAccessToken;
    const map = new mapboxgl.Map({
        container: 'assign_personnel_map',
        style: MapConfig.regularStyle,
        center: siteCoordinates.split(" "),
        zoom: MapConfig.startingZoom,
        minZoom: MapConfig.zoomOutMax,
        maxZoom: MapConfig.zoomInMax
    });
    fitMapToBounds(map, siteId, siteCoordinates);
    map.on('load', () => {
        listenForAddCurrentPersonnel();
        listenForDoneAssigning();
        listenForWorkerList();
        let worker_ids = $("#worker_ids").val().split(",");
        let is_role_search = $("#is_role_search").val();
        let roles = $("#roles").val();
        let competencies = $("#competencies").val();

        addPolygonsMouseOver(map, 'polygons');
        imageDataAjaxCall(map, null, null, null, false, true, true, false, null, siteId, null, false, false, true).then(
            response => geofenceAjaxCall(
                map,
                geofenceIds,
                null,
                null,
                false,
                false,
                false,
                false,
                null,
                false,
                null,
                true,
                false,
                worker_ids,
                is_role_search,
                roles,
                competencies
            )
        )
    });
}

function listenForAddCurrentPersonnel() {
    $("#personnel-popout-add-personnel").on('click', function() {
        Swal.fire({
            title: 'Add personnel to zone',
            text: "Are you sure you want to add the selected personnel to this zone?",
            icon: 'info',
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                let is_role_search = $("#is_role_search").val();
                let roles = $("#roles").val();
                let competencies = $("#competencies").val();
                let worker_ids = $("#worker_ids").val().split(",");
                let geofence_id = $("#personnel-popout-add-personnel").data('geofence_id');
                let geofence_name = $("#popout-zone-name").val();
                let exceptionsContainer = document.getElementById('exceptions-container');
                let exceptionsList = document.getElementById('exceptions-list-body');
                $.ajax({
                    type: 'POST',
                    url: `${Config.apiUrl}internal_personnel/update_personnel_zone_access?apikey=${Config.apiKey}&is_role_search=${is_role_search}&roles=${roles}&competencies=${competencies}&worker_ids=${worker_ids}&geofence_id=${geofence_id}`,
                    dataType: "json",
                    success: data => {
                        Swal.fire("Done!", "Personnel Added!", "success").then((done) => {
                            exceptionsContainer.style.display = "block";
                            if (is_role_search && is_role_search == "true"){
                                createExceptionRow(data.full_role_name, geofence_id, geofence_name, true, exceptionsList);
                            }

                            if(worker_ids.length > 0){
                                for (let i = 0; i < worker_ids.length; i++) {
                                    if(worker_ids[i] == ''){
                                        continue
                                    }
                                    createExceptionRow(worker_ids[i], geofence_id, geofence_name, true, exceptionsList);
                                }
                            }
                        })
                    },
                    error: function() {
                        Swal.fire('There was a problem adding the selected personnel to the zone.')
                    }
                });
            } else {
                Swal.fire('Personnel not added.')
            }
        })
    });
}

function listenForWorkerList() {
    $("#expand-worker-list").on("click", function() {
        let worker_list = $(".personnel-list");
        if(worker_list.is(":visible")) {
            worker_list.hide();
        }else{
            worker_list.show();
        }
    })
}
function listenForCheckbox() {
    let personnel_select = $(".personnel-hidden-select");
    let workers_with_all_competencies = $("#workers_with_exact_competencies").val();
    let is_role_search = $("#is_role_search");
    let add_to_zones_button = $("#add-to-zones-button");

    $(".select-all-personnel-checkbox").on("click", function() {
        if($(this).is(':checked')) {
            $('.personnel-checkbox').trigger('click')
        }else {
            $('.personnel-checkbox').trigger('click')
        }
    })

    $(".personnel-checkbox").on("click", function() {
        let select_string = ".personnel-hidden-select option[value=" + $(this).attr('checked_value') + "]"
        if($(this).is(':checked')) {
            $(select_string).prop('selected', true);
        }else{
            $(select_string).prop("selected", false);
        }
        if(personnel_select.val().length) {
            add_to_zones_button.prop('disabled', false);
            add_to_zones_button.removeClass('is-disabled');
        }else{
            add_to_zones_button.prop('disabled', true);
            add_to_zones_button.addClass('is-disabled');
        }
    })
}

function listenForDoneAssigning() {
    $("#assign-personnel-done").on("click", function() {
        Swal.fire({
            title: 'Finished?',
            text: "Do you want to return to the personnel screen?",
            icon: 'info',
            showCancelButton: true,
        }).then((result) => {
            if(result.isConfirmed) {
                let url = window.location.href;
                url = window.location.origin + '/personnel'
                window.location.replace(url);
            }
        })
    })
}

function listenForExpand() {
    $(".expand-worker").on("click", function() {
        let data = $(this).data('worker-id');
        let hidden_row = $("#actions-" + data);
        if(hidden_row.is(":visible")){
            $(".actions-row").hide();
        } else {
            $(".actions-row").hide();
            hidden_row.fadeIn();
        }
    })
}
function listenForSwitch(personnel_switch) {
    let personnel_form = $("#personnel-form");
    let role_form = $("#role-form");
    personnel_switch.change(function() {
        if(this.checked) {
            personnel_form.hide();
            role_form.show();
        }else{
            personnel_form.show();
            role_form.hide();
        }
    })
}

const listenForUnallocation = (lost_link, broken_link) => {
    broken_link.on("click", function() {
        if(!$(this).is(':disabled')) {
            let user_id = $(this).data('worker-id');
            performUnallocation(false, true, user_id);
        }
    })

    lost_link.on("click", function() {
        if(!$(this).is(':disabled')) {
            let user_id = $(this).data('worker-id');
            performUnallocation(true, false, user_id);
        }
    })
}

function performUnallocation(is_lost, is_broken, user_id){
    Swal.fire({
        title: `Report Device ${is_lost ? 'Lost' : 'Broken'}`,
        text: `Are you sure you want to Report ${user_id}'s device as ${is_lost ? 'lost' : 'broken'}?`,
        icon: 'warning',
        showCancelButton: true,
    }).then((result) => {
        if(result.isConfirmed){
            $.ajax({
                type: 'POST',
                url: `${Config.apiUrl}dashboard/unallocate_device_from_user?apikey=${Config.apiKey}&worker_id=${user_id}&is_lost=${is_lost}&is_broken=${is_broken}`,
                success: data => {
                    Swal.fire("Done!", `Device successfully reported ${is_lost ? 'lost' : 'broken'}!`, "success");
                },
                error: data => {
                    Swal.fire("Error trying to unallocate device from " + user_id);
                }
            });
        }
    })
}

let competencies_with_expiration_dates = {}
let stop_propagation = false;

function listenForEditClick() {
    $(".edit-personnel").on("click", function() {
        let worker_id = $(this).data('worker-id');
        let edit_personnel_label = $(".edit-personnel-label." + worker_id);
        let edit_personnel_stat = $(".edit-personnel-stat." + worker_id);
        let role_select = $(".personnel-role-dropdown." + worker_id);
        let new_role_input = $(".personnel-new-role." + worker_id);
        let competencies_select_div = $(".competencies_select_div_" + worker_id);
        let competencies_select = $(".personnel-competencies-select." + worker_id);
        let starting_competencies = competencies_select.val();

        // listen for manually entered text in the input field
        let competencies_select_input = competencies_select.find('input');
        competencies_select_input.on('change', function(event) {
            if($(this).val() && $(this).val() != ''){
                let new_input = $(this).val();
                setExpiryDate(new_input, competencies_select)
            }

            stop_propagation = true;
        });

        // listen for changes when user selects from the dropdown
        competencies_select.on('change', function(event) {
            if($(this).val() && $(this).val() != '' && stop_propagation == false){
                if(Object.keys(competencies_with_expiration_dates).length > $(this).val().length) {
                    // must have removed a competency, so delete the removed competency
                    let values = $(this).val();
                    let keys = Object.keys(competencies_with_expiration_dates);
                    $.each(keys, function(i) {
                        if (!values.includes(keys[i])) {
                            delete competencies_with_expiration_dates[keys[i]];
                        }
                    })
                }

                // add the new competency and expiry date
                let new_input = $(this).val().pop();
                if (!Object.keys(competencies_with_expiration_dates).includes(new_input)) {
                    setExpiryDate(new_input, competencies_select)
                }
            }

            stop_propagation = false
        })

        role_select.on('change', function() {
            if($(this).val() == 'New Role') {
                Swal.fire({
                    title: "New Role",
                    text: `Please enter the name of the new role`,
                    input: "text",
                }).then((result) => {
                    role_select.append($('<option>', {
                        value: result.value,
                        text: result.value,
                        selected: true
                    }));
                    role_select.val(result.value);
                })
            }
        })

        if($(this).data('is-save')){
            Swal.fire({
                title: 'Save Personnel',
                text: "Are you sure to save these personnel details?",
                icon: 'info',
                showCancelButton: true,
            }).then((result) => {
                if (result.isConfirmed) {
                    let role = $("#roles.personnel-input." + worker_id).val();

                    $.ajax({
                        type: 'POST',
                        url: `${Config.apiUrl}internal_personnel/save_personnel_details?apikey=${Config.apiKey}`,
                        data: {
                            worker_id: worker_id,
                            role: role,
                            competencies: competencies_with_expiration_dates
                        },
                        success: data => {
                            Swal.fire("Personnel Saved!")

                            let competency_names = Object.keys(competencies_with_expiration_dates);
                            let competencies_string = ''
                            if (competency_names && competency_names != ""){
                                competencies_string = competency_names.join("\r\n");
                            }else{
                                competencies_string = "None";
                            }
                            $(".data-box__stat.competencies." + worker_id).text(competencies_string);
                            $(".data-box__stat.role." + worker_id).text(role);
                            $(".data-box__stat." + worker_id).show();
                            $(".td-role." + worker_id).text(role);
                            $(".td-competencies." + worker_id).text(competencies_string);
                            role_select.hide();
                            competencies_select_div.hide();
                            edit_personnel_stat.show();
                            edit_personnel_label.html('Edit Personnel');
                            edit_personnel_stat.html('<i class="fas fa-edit"></i> Edit');
                            $(this).data('is-save', false);
                        },
                        error: data => {
                            Swal.fire("Error trying to save personnel");
                        }
                    })
                }
            })
        }else{
            $(".data-box__stat." + worker_id).hide();
            $(".personnel-input." + worker_id).show();
            edit_personnel_stat.show();
            edit_personnel_label.html('Save Personnel');
            edit_personnel_stat.html('<i class="fas fa-check"></i> Save');
            role_select.show();
            new_role_input.hide();
            competencies_select_div.show();
            stop_propagation = false;
            $(this).data('is-save', true);

            // populate the competencies that are already selected when we load
            $.each(starting_competencies, function(i) {
                competencies_with_expiration_dates[starting_competencies[i]] = ''
            })
        }
    })
}

function setExpiryDate(competency_name, competencies_select) {
    Swal.fire({
        title: "Expiry Date",
        text: `Please enter an expiry date for ${competency_name} in the format DD-MM-YYYY`,
        input: "text",
    }).then((result) => {
        if(result.isConfirmed) {
            if(moment(result.value, 'DD-MM-YYYY').isValid()) {
                let selectize = competencies_select[0].selectize;
                selectize.addOption({text:competency_name, value: competency_name});
                selectize.addItem(competency_name);
                selectize.refreshOptions();

                competencies_with_expiration_dates[competency_name] = result.value;
            } else {
                Swal.fire("Invalid Format")
            }
        }
    })
}

function listenForMapClick() {
    $("#preview_map_personnel").on("click", function() {
        let overlay = $("#personnel_preview_overlay");
        overlay.show();
        overlay.fadeOut(5000);
    })
}